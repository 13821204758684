<template>
  <v-dialog
      :value="dialog"
      @input="toggleDialog"
      max-width="1200px"

  >
    <v-card>

      <v-card>
        <v-card-title class="justify-space-between">
            <span class="subTitle">
              베팅상세내역
            </span>
        </v-card-title>
        <v-simple-table
        >
          <thead>
          <tr>
            <th class="text-center" style="width:15%">
              경기시간
            </th>
            <th class="text-center">
              리그
            </th>
            <th class="text-center" style="width:20%">
              승(HOME)
            </th>
            <th class="text-center" style="width:10%">
              무(기준점)
            </th>
            <th class="text-center" style="width:20%">
              패(AWAY)
            </th>
            <th class="text-center" style="width:10%">
              상태
            </th>
            <th></th>
          </tr>
          </thead>

          <tbody
              v-for="(item, i) in betList"
              :key="i"
          >
          <tr
              v-for="(betItem, j) in item.items"
              :key="j"
              class="game-info">
            <td class="text-center">
              {{ betItem.matchedAt }}
            </td>
            <td class="text-center">
              {{ betItem.league }}
            </td>

            <td colspan="3" v-if="betData.menuCode === 'vdogracings'">
              <div class="d-flex justify-space-between">
                <div class="dog d-flex justify-space-between" :class="item.whichBet === 'DOG_1' ? 'betSelected font-weight-bold': ''">
                  <div class="align-center text-left">
                    {{ item.teamHome }}
                  </div>
                  <div class="text-right">
                    <span>{{ item.oddsHome }}</span>
                  </div>
                </div>
                <div class="dog d-flex justify-space-between"  :class="item.whichBet === 'DOG_2' ? 'betSelected font-weight-bold': ''">
                  <div class=" align-center text-left">
                    {{ item.teamAway }}
                  </div>
                  <div class="text-right">
                    <span>{{ item.oddsAway }}</span>
                  </div>
                </div>
                <div class="dog d-flex justify-space-between" :class="item.whichBet === 'DOG_3' ? 'betSelected font-weight-bold': ''">
                  <div class=" align-center text-left">
                    {{ item.team3 }}
                  </div>
                  <div class="text-right">
                    <span>{{ item.oddsDraw }}</span>
                  </div>
                </div>
                <div class="dog d-flex justify-space-between" :class="item.whichBet === 'DOG_4' ? 'betSelected font-weight-bold': ''">
                  <div class="d-flex align-center text-left">
                    {{ item.team4 }}
                  </div>
                  <div class="text-right">
                    <span>{{ item.oddsExtra1 }}</span>
                  </div>
                </div>
                <div class="dog d-flex justify-space-between" :class="item.whichBet === 'DOG_5' ? 'betSelected font-weight-bold': ''">
                  <div class="d-flex align-center text-left">
                    {{ item.team5 }}
                  </div>
                  <div class="text-right">
                    <span>{{ item.oddsExtra2 }}</span>
                  </div>
                </div>
                <div class="dog d-flex justify-space-between" :class="item.whichBet === 'DOG_6' ? 'betSelected font-weight-bold': ''">
                  <div class="d-flex align-center text-left">
                    {{ item.team6 }}
                  </div>
                  <div class="text-right">
                    <span>{{ item.oddsExtra3 }}</span>
                  </div>
                </div>
              </div>
            </td>

            <td
                v-if="betData.menuCode !== 'vdogracings'"
                class="text-center"
                :class="betItem.whichBet === 'HOME' ? 'betSelected font-weight-bold': ''">
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center text-left">
                  {{ betItem.teamHome }}
                  <span v-if="betItem.gameCode ==='OVER_UNDER'">
                          <v-icon
                              medium
                              color="red"
                          >
                              mdi-arrow-up-bold-circle-outline
                          </v-icon>
                </span>
                </div>
                <div class="d-flex align-center">
                  {{ betItem.oddsHome }}
                </div>
              </div>
            </td>
            <td class="text-center"
                v-if="betData.menuCode !== 'vdogracings'"
                :class="betItem.whichBet === 'DRAW' ? 'betSelected font-weight-bold': ''"
            >
              <div class="" v-if="betItem.gameCode === 'MATCH' ">
                {{ betItem.oddsDraw === 0 ? 'vs' : betItem.oddsDraw }}
              </div>
              <div v-else-if="betItem.gameCode === 'BALL_SIZE'">
                <div class="align-center text-center">
                  소중대[중]<span>{{ betItem.oddsDraw }}</span>
                </div>
              </div>
              <div class="" v-else>
                {{ betItem.handicap }}
              </div>
            </td>
            <td class="text-center"
                v-if="betData.menuCode !=='vdogracings'"
                :class="betItem.whichBet === 'AWAY' ? 'betSelected font-weight-bold': ''"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  {{ betItem.oddsAway }}
                </div>
                <div class="d-flex align-center text-right">
                    <span v-if="betItem.gameCode ==='OVER_UNDER'">
                  <v-icon
                      medium
                      color="indigo"
                  >
                      mdi-arrow-down-bold-circle-outline
                  </v-icon>
                </span>
                  {{ betItem.teamAway }}
                </div>
              </div>
            </td>

            <td class="text-center">
              <span>
                {{ betItem.status }}
              </span>
            </td>
            <td  class="text-center">
              <v-btn
                  tile
                  color="error"
                  class="ma-2"
                  @click="cancelBetItem(betItem.betItemId)"
                  :disabled="betItem.status !== '대기'"
              >
                취소
              </v-btn>

            </td>
          </tr>

          <tr class="bet-info">
            <td class="text-center">
              {{ item.memberBasicInfo.username }}({{ item.memberBasicInfo.nickname }})
            </td>
            <td class="text-center">
              <span>베팅금</span> : {{ item.betAmount | comma }}
            </td>
            <td class="text-center">
              <span v-if="item.result==='BET'">예상당첨금 : {{ item.estimateAmount | comma}}</span>
              <span v-else>당첨금 : {{ item.winAmount | comma}}</span>
            </td>
            <td colspan="2" class="text-center">베팅시간 : {{item.betAt}}</td>
            <td class="text-center">
            </td>
            <td class="text-center">
            </td>

          </tr>



          </tbody>
        </v-simple-table>

        <div
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-7': 'ma-5'"
            class=" text-center"
        >
          <v-pagination
              v-model="page"
              :length="pageLength"
              @input="next"
              color="#4c4c4c"
          ></v-pagination>
        </div>

      </v-card>



    </v-card>
  </v-dialog>
</template>

<script>

// import axios from "axios";

export default {
  data () {
    return {
      page: 1,
      pageLength: 0,
      total: 0,
      isEmpty: false,
      betList:{},
      menuCode : this.betData.menuCode
    }


  },

  components: {},
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    betData: {
      type: Object,
    },

  },
  methods: {

    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:dialog', !this.dialog);
      // this.$emit('input');
    },
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id);
    },

    getBetList: function () {
      axios
          .get(`/admin/bet/histories/byGame/${this.betData.id}`,
              {
                params: {
                  page:this.page-1,
                  size:50,
                  whichBet:this.betData.whichBet,
                  menuCode:this.betData.menuCode
                },
              })
          .then(({ data }) => {
            console.log(data)

            this.betList = data.content;
            
            for (let i=0; i < this.betList.length; i++) {

              if (this.betList[i].items[0].whichBet === 'ODD' || this.betList[i].items[0].whichBet === 'OVER' || this.betList[i].items[0].whichBet === 'SMALL' ){
                this.betList[i].items[0].whichBet = 'HOME'
              }
              if (this.betList[i].items[0].whichBet === 'EVEN' || this.betList[i].items[0].whichBet === 'UNDER' || this.betList[i].items[0].whichBet === 'LARGE' ){
                this.betList[i].items[0].whichBet = 'AWAY'
              }
              if (this.betList[i].items[0].whichBet === 'MEDIUM' ){
                this.betList[i].items[0].whichBet = 'DRAW'
              }
            }

          console.log('this betList',this.betList)
            
            this.pageLength = data.totalPages;
            this.total = data.totalElements;
            this.isEmpty = data.empty;
          })
          .catch( ex => {

          })
    },

    next: function (page) {
      console.log('next',page)
      this.getBetList();
    },

  },



  watch: {
    dialog: function () {
      this.getBetList();

    }
  },
  mounted() {





  },

}
</script>

<style>
.bet-info {background-color: #2f3232}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 48px;
  padding: 0;
}

.centered-input input {
  text-align: center
}
</style>