<template>
  <div class="">
    <div class="d-flex justify-space-between py-3">
      <div class="pl-3 align-self-center">
        <v-btn
            icon
            class="px-2"
            x-large
            color="#4c4c4c"
            @click="$router.go(-1)"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>

      </div>

      <div class="align-self-center">
        <h2 class="font-weight-medium">
          배팅 내역
        </h2>
      </div>
      <div class="pr-3 align-self-center">
        <v-btn
            :class="search.use ? '': 'hidden'"
            icon
            class="px-2"
            x-large
            color="#4c4c4c"
            @click="dialog = !dialog"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </div>
    </div>

    <div>
      <v-card
          color="#4c4c4c"
          v-for="(item,i) in betList"
          :key="i"
          outlined
          class="depositListMobile"
          @click="getBetDetailData(item.betId)"
      >
        <v-card-subtitle class=""
                         @click.stop.prevent="goMemberDetail(item)"
        >
          <div class="d-flex">
            <div class="px-1">
              {{ item.memberBasicInfo.username }}
            </div>
            <div class="font-orange">
              ({{ item.memberBasicInfo.nickname }})
            </div>
          </div>
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex" v-on="on">
                  <div class="ml-2" v-if="item.memberBasicInfo.hasMemo">
                    <v-icon
                        style="font-size:18px"
                        v-bind="attrs"
                        @mouseover="getMemo(item.memberBasicInfo.memberId)"
                    >mdi-message-alert-outline
                    </v-icon>
                  </div>
                </div>
              </template>
              <span
                  v-if="item.memberBasicInfo.hasMemo"
                  v-html="memoContent.memo"
                  class="memoBox"
              ></span>
            </v-tooltip>
          </div>
        </v-card-subtitle>
        <v-card-title>
          <span class="betItemStatus"
                v-for="(betItem,j) in item.betItemsStatus"
                :key="j"
          >
             <v-btn
                 outlined
                 style="font-size:11px;"
                 :color="
                  betItem ==='베팅중' ? '#78909C' :
                  betItem ==='베팅종료' ? '#98C8DF' :
                  betItem ==='정산중' ? '#7986CB' :
                  betItem ==='미적중' ? '#448AFF' :
                  betItem ==='관리자취소' ? '#EEEEEE' :
                  betItem ==='적중' ? '#FF5252' : ''"
             >
                {{ betItem }}
              </v-btn>

            </span>
        </v-card-title>
        <v-card-subtitle class="py-2">
          <v-row
              no-gutters
          >
            <v-col
                cols="3"
            >
              배당 : {{ item.status === 'WON' ? item.winOdds : item.odds }}
            </v-col>
            <v-col
            >
              배팅액 : {{ item.betAmount | makeComma }}
            </v-col>
            <v-col
            >
              <div class="d-flex">
                <div class="pr-1">
                  적중금액 :
                </div>
                <div
                    v-if="item.result==='베팅중'"
                >
                  <v-badge
                      content="예상"
                      color="#757575"
                      rounded
                  >
                    {{ item.estimateAmount | makeComma }}
                  </v-badge>
                </div>
                <div
                  v-else
                >
                  {{ item.winAmount | makeComma }}
                </div>
              </div>


            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-subtitle>
          <div class="d-flex justify-space-between">
            <div>
              <div class="d-flex">
                <div>
                  배팅 시간
                </div>
                <div class="mx-5">
                  {{ item.betAt }}
                </div>
              </div>
              <div
                  class="d-flex"
              >
                <div>
                  정산 시간
                </div>
                <div class="mx-5 font-orange">
                  {{ item.calculationAt }}
                </div>
              </div>
            </div>
            <div>
              <v-btn
                  tile
                  color="error"
                  class="ma-2"
                  @click.stop.prevent="cancelBet(item.betId)"
                  :disabled="!item.canCancel"
              >
                취소
              </v-btn>
            </div>
          </div>
        </v-card-subtitle>
        <div
            :class="item.show ? '' : 'd-none'"
        >
          <bet-detail
              class="py-1"
              :ref="item.betId"
              :menuCode="item.menuCode"
              :betDetailList="betDetailList[item.betId]"
              v-on:cancelBetItem="cancelBetItem"
          >
          </bet-detail>
        </div>

        <v-divider></v-divider>
      </v-card>

    </div>

    <search-form-mobile
        v-if="!this.$route.params.id"
        :dialog.sync="dialog"
        :search="search"
        v-on:searchData="searchData"
        ref="searchForm"
    >
    </search-form-mobile>
  </div>
</template>

<script>
import SearchFormMobile from "@/components/_Default/SearchForm/Mobile";
import BetDetail from "@/components/Sport/BetHistory/Detail/Mobile";
export default {
  name: "BoardMobile",
  data() {
    return {
      dialog: false,
      startDateCalendars: false,
      endDateCalendars: false,
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10)

    }
  },
  props: {
    search: {
      type: Object
    },

    betList: {
      type: Array
    },
    betDetailList: {
      type: Object
    },
    printNumber: {
      type: Number
    },
    memoContent: {
      type: Object
    }
  },
  components: {
    BetDetail,
    SearchFormMobile,

  },
  methods: {
    initSearchStatusUsingBetDialog: function () {
      this.$refs.searchForm.initSearchStatusUsingBetDialog()
    },
    searchData: function (data) {
      this.$emit('searchData', data);
    },
    goRegist: function () {
      this.$emit('goRegist')
    },
    goMemberDetail: function (item) {
      console.log("asda",item)
      this.$emit('goMemberDetail', item.memberBasicInfo.memberId)
    },

    getBetDetailData: function (id) {
      this.$emit('getBetDetailData', id)
    },
    editData: function (item) {
      this.$emit('editData', item)
    },
    deleteData: function (id) {
      this.$emit('deleteData', id)
    },
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },
    openDetail: function (id) {
      this.$forceUpdate()
      this.$refs[id][0].openDetail(this.betDetailList[id])
    },
    getMemo: function (id) {
      this.$emit('getMemo', id)
    },

  }
}
</script>

<style scoped>

</style>