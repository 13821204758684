<template>
  <v-card>
    <v-tabs
        v-model="tab"
        background-color="transparent"
        grow
        show-arrows
    >
      <v-tab
          v-for="(item, i) in siteMenuList"
          :key="i"
          link
          exact-path
          :to="item.path"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  name: "SubMenu",
  data: () => ({
    tab: null,
    siteMenuList: [
      {title:'환경 설정', path: '/setting/site'},
/*      {title:'게임 설정', path: '/setting/game'},*/
      {title:'차단 IP 관리', path: '/setting/blockip'},
      {title:'은행관리', path: '/setting/bank'},
      {title:'입금계좌관리', path: '/setting/deposit'},
      {title:'비밀번호변경', path: '/setting/password'}
    ],
  }),

}
</script>

<style scoped>

</style>