<template>

  <div class="">
    <div v-if='this.$route.params.menuCode !== undefined'
         class="subTitle">베팅내역 ({{ this.$route.params.menuCode}})</div>
    <!--        검색바-->
    <div class="mb-5">
      <search-form-pc
          :search="search"
          v-on:goRegist="goRegist"
          v-on:searchData="searchData"
          btnText=""
          ref="searchForm"
          >
      </search-form-pc>
    </div>
    <!--        테이블-->
    <div>
      <v-simple-table
          class="dataList"
      >
        <thead>
        <tr>
          <th v-if="printNumber !== undefined"
              class="text-center">
            No.
          </th>
          <th class="text-center" v-if="$route.params.menuCode ==='MINI_GAME'">
            구분
          </th>
          <th class="text-center">
            <div class="d-flex">
              <div>
                ID
              </div>
              <div class="pl-2 font-orange">
                (닉네임)
              </div>
            </div>
          </th>
          <th class="text-center">
            구분
          </th>
          <th class="text-center" style="width:30%">
            진행내역
          </th>
<!--          <th class="text-center">
            배당
          </th>-->
          <th class="text-center">
            베팅액
          </th>
          <th class="text-center">
            적중금액
          </th>
          <th class="text-center">
            베팅시간
          </th>
          <th class="text-center">
            정산시간
          </th>
          <th class="text-center">취소</th>

        </tr>
        </thead>

        <tbody
            v-for="(item,i) in betList"
            :key="i"
        >
        <tr @click="getBetDetailData(item.betId)">
          <td
              v-if="printNumber !== undefined"
              class="text-center">
            {{ printNumber + i }}
          </td>
          <td class="text-center" v-if="$route.params.menuCode ==='MINI_GAME'">
            {{ item.menuCode}}
          </td>
          <td class="text-center">

              <div class="d-flex" @click.stop.prevent="goMemberDetail(item)">

                <div>
                  {{ item.memberBasicInfo.username }}
                </div>

                <div class="pl-2 font-orange">
                  ({{ item.memberBasicInfo.nickname }})
                </div>

                <div>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex"  v-on="on">
                        <div class="ml-2" v-if="item.memberBasicInfo.hasMemo">
                          <v-icon
                              style="font-size:18px"
                              v-bind="attrs"
                              @mouseover="getMemo(item.memberBasicInfo.memberId)"
                          >mdi-message-alert-outline</v-icon>
                        </div>
                      </div>
                    </template>
                    <span
                        v-if="item.memberBasicInfo.hasMemo"
                        v-html="memoContent.memo"
                        class="memoBox"
                    ></span>
                  </v-tooltip>
                </div>
              </div>
          </td>
          <td class="text-center">
            {{item.menuCodeText}}
          </td>
          <td class="text-center">
            <span class="betItemStatus"
                  v-for="(betItem,j) in item.betItemsStatus"
                  :key="j"
            >
             <v-btn
                 outlined
                 style="font-size:11px;"
                 :color="
                  betItem ==='베팅중' ? '#78909C' :
                  betItem ==='베팅종료' ? '#98C8DF' :
                  betItem ==='정산중' ? '#7986CB' :
                  betItem ==='미적중' ? '#448AFF' :
                  betItem ==='관리자취소' ? '#EEEEEE' :
                  betItem ==='적중' ? '#FF5252' : ''"
             >
                {{ betItem }}
              </v-btn>

            </span>
          </td>
<!--          <td class="text-center">
            <span v-if="$route.params.menuCode !== 'CASINO'">{{ item.status === 'WON' ? item.winOdds : item.odds }} </span>
            <span v-else>-</span>
          </td>-->
          <td class="text-center">
            {{ item.betAmount | makeComma }}
          </td>

          <td class="text-center">
            <span v-if="item.result==='베팅중'">
              <v-badge
                  content="예상"
                  color="#757575"
                  rounded
              >
              {{ item.estimateAmount | makeComma }}
              </v-badge>
            </span>
            <span v-else>{{ item.winAmount | makeComma }}</span>
          </td>
          <td class="text-center">
            {{ item.betAt }}
          </td>
          <td class="text-center">
            {{ item.calculationAt  }}
          </td>
          <td class="text-center">
              <v-btn
                  tile

                  color="error"
                  class="ma-2"
                  @click.stop.prevent="cancelBet(item.betId)"
                  :disabled="!item.canCancel"
              >
                취소
              </v-btn>
          </td>

        </tr>
        <tr
            class="px-1"
            :class="item.show ? '' : 'd-none'"
        >
          <td colspan="10"
              class="px-0">
            <bet-detail
                class="py-1"
                :ref="item.betId"
                :menuCode="item.menuCode"
                :betDetailList="betDetailList[item.betId]"
                v-on:cancelBetItem="cancelBetItem"
                v-on:callResult="callResult"
            >
            </bet-detail>
          </td>

        </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import BetDetail from "@/components/Sport/BetHistory/Detail/Pc";
import SearchFormPc from "@/components/_Default/SearchForm/Pc";

export default {
  name: "BoardPc",
  data() {
    return {
      startDateCalendars: false,
      endDateCalendars: false,
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10)

    }
  },
  components: {
    BetDetail,
    SearchFormPc
  },

  props: {

    search: {
      type:Object
    },

    betList: {
      type: Array
    },
    betDetailList: {
      type: Object
    },
    printNumber:{
      type: Number
    },
    memoContent: {
      type:Object
    }
  },
  methods: {
    initSearchStatusUsingBetDialog: function () {
      this.$refs.searchForm.initSearchStatusUsingBetDialog()
    },
    searchData: function (data) {
      this.$emit('searchData', data);
    },
    goRegist: function () {
      this.$emit('goRegist')
    },
    goMemberDetail: function (id) {
      this.$emit('goMemberDetail',id)
    },

    getBetDetailData: function (id) {
      console.log(id)
      this.$emit('getBetDetailData', id)
    },
    editData: function (item) {
      this.$emit('editData', item)
    },
    deleteData: function (id) {
      this.$emit('deleteData', id)
    },
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },
    openDetail: function (id) {
      this.$forceUpdate()
      this.$refs[id][0].openDetail(this.betDetailList[id])
    },
    getMemo: function (id) {
      this.$emit('getMemo', id)
    },
    callResult: function(item){
      this.$emit('callResult',item)
    }


  },
  watch: {

  }
}
</script>

<style scoped>


</style>