<template>
  <v-dialog
      :value="dialog"
      @input="toggleDialog"
      max-width="1500px"

  >
    <v-card>
      <v-card-title class="justify-space-between  pb-3">
        <div class="text-h5">
          배팅 내역
        </div>
        <div>
          <v-btn
              icon
              class=""
              x-large
              color="#4c4c4c"
              @click="toggleDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <bet-preview-pc
          v-if="$vuetify.breakpoint.mdAndUp"
          :search="search"
          :betList="betContent"
          :betDetailList="betDetailList"
          :memoContent="memoContent"
          v-on:getBetDetailData="getBetDetailData"
          v-on:cancelBet="cancelBet"
          v-on:cancelBetItem="cancelBetItem"
          v-on:goMemberDetail="goMemberDetail"
          v-on:getMemo="getMemo"
          v-on:searchData="searchData"
          ref="betList"
      ></bet-preview-pc>
      <bet-preview-mobile
          v-else
          :search="search"
          :betList="betContent"
          :betDetailList="betDetailList"
          :memoContent="memoContent"
          v-on:getBetDetailData="getBetDetailData"
          v-on:cancelBet="cancelBet"
          v-on:cancelBetItem="cancelBetItem"
          v-on:goMemberDetail="goMemberDetail"
          v-on:getMemo="getMemo"
          v-on:searchData="searchData"
          ref="betList"
      ></bet-preview-mobile>

      <div
          :class="$vuetify.breakpoint.mdAndUp ? 'mt-7': 'ma-5'"
          class=" text-center"
      >
        <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="20"
            @input="next"
            color="#4c4c4c"
        ></v-pagination>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>

import BetPreviewPc from "@/components/Sport/BetHistory/Preview/Pc";
import BetPreviewMobile from "@/components/Sport/BetHistory/Preview/Mobile";
import SearchFormPc from "@/components/_Default/SearchForm/Pc.vue";


export default {
  name: 'betDialogPc',
  data() {
    return {
      rules: {
        nullCheck: v => !!v || '',
      },

      page: 1,
      pageLength: this.betList.totalPages,
      total: this.betList.totalElements,
      isEmpty: this.betList.empty,

      search: {
        use: true,
        usedCalendar: true,
        usedAgency: false,
        usedMemberStatus: false,
        usedBetStatus: true,
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        defaultSelectValue: '',
        defaultSelectList: [],
        betStatusValue: '',
        betStatusList: [
          {text: '전체', value: ''},
          {text: '진행중', value: 'BET'},
          {text: '적중', value: 'WON'},
          {text: '미적중', value: 'NOT_WON'},
          {text: '취소', value: 'CANCEL'},
        ],
        usedMenuCode: true,
        menuCodeListValue: 'CASINO',
        menuCodeList: [
          {text: '전체', value: 'CASINO'},
          {text: '라이브카지노', value: 'CASINO_LIVE'},
          {text: '슬롯', value: 'CASINO_SLOT'},
        ],

        usedSortType: true,
        sortTypeListValue: null,
        sortTypeList: [
          {text: '시간', value: null},
          {text: '베팅금', value: 'bet'},
          {text: '당첨금', value: 'hit'},
        ]

      },
      betContent: [],
      searchRequest: {}

    }


  },

  components: {
    BetPreviewPc,
    BetPreviewMobile,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    betList: {
      type: Object
    },
    betDetailList: {
      type: Object
    },

    memoContent: {
      type: Object
    }


  },
  methods: {

    searchData: function (data) {
      console.log('ssss', data)
      this.page = 1
      this.searchRequest = {
        from: data.startDate,
        to: data.endDate,
        agencyId: data.agencyId,
        status: data.betStatus,
        sortType: data.sortType,
        menuCode: data.menuCode,
        page: this.page
      }
      switch (data.category) {
        case 'username':
          this.searchRequest.username = data.data
          break;
        case 'nickname':
          this.searchRequest.nickname = data.data
          break;
        case 'clientIp':
          this.searchRequest.clientIp = data.data
          break;
        default :
          break;
      }

      console.log('search', data)

      this.getBetList(this.betList.memberId, this.searchRequest)
    },

    mappingData: function () {
    },
    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:dialog', !this.dialog);
      // this.$emit('input');
    },
    getBetList: function (id, data) {
      console.log(id, data)
      this.$emit('getBetList', id, data)
    },

    next: function (page) {
      this.searchRequest.page = page
      this.getBetList(this.betList.memberId, this.searchRequest)
    },
    goMemberDetail: function (item) {
      this.$emit('goMemberDetail', item)
    },

    getBetDetailData: function (id) {
      this.$emit('getBetDetailData', id)
    },
    editData: function (item) {
      this.$emit('editData', item)
    },
    deleteData: function (id) {
      this.$emit('deleteData', id)
    },
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },
    openDetail: function (id) {
      this.$refs.betList.openDetail(id)
      // this.$refs[id][0].openDetail(this.betDetailList[id])
    },
    getMemo: function (id) {
      this.$emit('getMemo', id)
    },

  },


  watch: {
    dialog: function () {
      if (this.dialog) {
        this.page = 1
        this.pageLength = this.betList.totalPages
        this.total = this.betList.totalElements
        this.isEmpty = this.betList.empty
        // this.betContent = this.betList.content
      } else {
        this.page = 1
        this.pageLength = 0
        this.total = 0
        this.printNumber = 1
        this.isEmpty = false
        this.$refs.betList.initSearchStatusUsingBetDialog()
        this.searchRequest={}
      }
    },
    betList: {
      handler: function (val, oldVal) {
        // console.log('change betList')
        // console.log(val, oldVal)
        // if (val.memberId !== oldVal.memberId) {
        //   this.page = 1
        //   this.pageLength = this.betList.totalPages
        //   this.total = this.betList.totalElements
        //   this.isEmpty = this.betList.empty
        // }
        this.betContent = val.content
        if (val.empty) {
          this.page = 1
          this.pageLength = 0
          this.total = 0
        } else {
          this.pageLength = val.totalPages
          this.total = val.total
        }
      },
      deep: true
    }
  },
  mounted() {
  },

}
</script>

<style>
.bet-info {
  background-color: #2f3232
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 48px;
  padding: 0;
}
</style>