import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";

Vue.use(Vuex);

// LOGIN 정보 보관
export default new Vuex.Store({
  // 컴포넌트 간 공유될 DATA
  state: {
    accessToken: null,
    refreshToken: null,
    memberId: null,
    username: null,
    nickname: null,
    grade: null,
  },
  getters: {},
  // state 값을 변경하는 로직 (setter 와 유사한 개념)
  mutations: {

    saveSignInInfo: function (state, payload) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.memberId = payload.memberId;
      state.username = payload.username;
      state.nickname = payload.nickname;
      state.grade = payload.grade;
      // 토큰을 로컬 스토리지에 저장
      localStorage.setItem('accessToken',payload.accessToken)
      localStorage.setItem('refreshToken',payload.refreshToken)
      localStorage.setItem('memberId',payload.memberId)
      localStorage.setItem('username',payload.username)
      localStorage.setItem('nickname',payload.nickname)
      localStorage.setItem('grade',payload.grade)


      // localStorage.accessToken = payload.accessToken;
      // localStorage.refreshToken = payload.refreshToken;
      // localStorage.memberId = payload.memberId;
      // localStorage.username = payload.username;
      // localStorage.nickname = payload.nickname;
      // localStorage.grade = payload.grade;
    },
    updateToken: function (state,payload) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;

      localStorage.setItem('accessToken',payload.accessToken)
      localStorage.setItem('refreshToken',payload.refreshToken)
      // localStorage.accessToken = payload.accessToken;
      // localStorage.refreshToken = payload.refreshToken;
    },
    // LOGIN(state, {accessToken}) {
    //   state.accessToken = accessToken;
    //
    //   // 토큰을 로컬 스토리지에 저장
    //   localStorage.accessToken = accessToken;
    // },
    LOGOUT(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.memberId = null;
      state.username = null;
      state.nickname = null;
      state.grade = null;

      delete localStorage.accessToken;
      delete localStorage.refreshToken;
      delete localStorage.memberId;
      delete localStorage.username;
      delete localStorage.nickname;
      delete localStorage.grade;

    }
  },
  // 사용자의 입력에 따라 반응할 METHODS
  actions: {
    LOGIN: async function ({commit}, {username, password,role}) {
      return await axios
          .post("/admin/auth/signin", {
            username,
            password,
            role: "ROLE_ADMIN",
            domain : location.host
          })
          .then(response => {
            console.log('requireAuth LOGIN', response,localStorage.accessToken)
            commit("saveSignInInfo", {
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
              memberId: response.data.memberId,
              username: response.data.username,
              nickname: response.data.nickname,
              grade: response.data.grade
            });

            // 성공적으로 로그인이 되었을 경우
            if (response.status === 200) {
              // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
              axios.defaults.headers.common["Authorization"] = `Bearer ${
                  response.data.accessToken
              }`;
            } else {
              // alert("로그인 실패");
            }
          });
      // .catch(ex => {
      //   console.warn("ERROR!!!!! : ", ex);
      //   alert("로그인 실패");
      // });
      // return axios.post(`${resourceHost}/login`, {email, password})
      //   .then(({data}) => {
      //     commit("LOGIN", data);
      //     // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
      //     axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
      //   });
    },
    REFRESHTOKEN: async function ({commit}) {
      // axios.defaults.headers.common["Authorization"] = undefined;
      return await axios
          .post("/admin/auth/refreshToken", {
            memberId: localStorage.getItem('memberId'),
            refreshToken: localStorage.getItem('refreshToken')
            // memberId: localStorage.memberId,
            // refreshToken: localStorage.refreshToken
          })
          .then(response => {
            console.log('requireAuth REFRESHTOKEN', response,localStorage.accessToken)
            commit("updateToken", {
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,

            });

            // 성공적으로 로그인이 되었을 경우
            if (response.status === 200) {
              // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
              axios.defaults.headers.common["Authorization"] = `Bearer ${
                  response.data.accessToken
              }`;
            } else {
              // alert("로그인 실패");
            }
          })

    },
    LOGOUT: function ({commit}) {
      axios.defaults.headers.common["Authorization"] = undefined;
      console.log('requireAuth LOGOUT',localStorage.accessToken)
      commit("LOGOUT");
    }
    // ,
    // ME: function(context) {
    //   axios.get("/api/v1/members/me")
    //     .then({ data } => {
    //     })
    //     .catch(ex => {
    //       console.warn("ERROR!!!!! : ", ex);
    //     });
    // }
  }
});

const enhanceAccessToken = () => {
  const {accessToken} = localStorage;
  console.log('requireAuth enhanceAccessToken',localStorage)
  if (!accessToken) {
    return;
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};
enhanceAccessToken();
