<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        clipped
        app
        ref="navigationDrawer"
        width="186"
    >
      <v-sheet
          color="grey darken-4"
          class="pa-0 customBg"
          ref="navigationDrawerTitle"
      >
<!--        <div class="logoTitle"></div>-->
      </v-sheet>

      <v-divider></v-divider>

      <v-list
          class="menuList font-13"
          ref="navigationMenu"
      >
        <perfect-scrollbar
            v-resize="onResize"
            :style="scrollerStyle"
        >

          <v-list-group
              v-for="(item, i) in menuItems"
              :key="i"
              no-action
              sub-group
              prepend-icon=""
              style="box-shadow: 1px 0px 2px rgba(33,33,33,0.6);"
          >

            <template v-slot:activator>
              <v-icon class="mr-2" style="color:rgba(255,255,255,0.12);font-size:18px">{{ menuItems[i].icon }}</v-icon>
              <v-list-item-content>
                <v-list-item-title
                    link
                    :to="item.path"
                    v-text="item.title"
                    class="appBar"
                    style="font-weight:600;"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="child in item.items"
                :key="child.title"
                :to="child.path"
                link
                exact-path
            >
              <v-list-item-content
                  @click="checkMenuTab(child.path)">
                <v-list-item-title
                    class="appBar menuChild"
                    link
                    v-text="child.title"

                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

        </perfect-scrollbar>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        id="default-app-bar"
        app
        fixed
        dense
        class="appBar customBg"
        color="transparent"
        clipped-left
        :height="$vuetify.breakpoint.mdAndUp? 140 : ''"
        flat
    >
      <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click="drawer = !drawer"
      />
      <div class="hidden-sm-and-down">
        <v-btn
            class=""
            elevation="1"
            text
            width="85"
            @click="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical' }}
          </v-icon>
        </v-btn>
      </div>
      <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-center w-100"
      >
        <notifications
            :dashBoardItem="dashBoardItem"
        />
        <status-board-pc
            :dashBoardItem="dashBoardItem"
            :soundCharge="soundCharge"
            :soundExchange="soundExchange"
            :soundCustomer="soundCustomer"
            :soundMember="soundMember"
            :overlay="overlay"
            v-on:move="move"
            ref="status"
        />
        <v-spacer />

      </div>
      <div
          v-else
          class="d-flex align-center w-100"
      >
        <v-spacer />
        <notifications
            :dashBoardItem="dashBoardItem"
        />
        <status-board-mobile
            :dashBoardItem="dashBoardItem"
            :soundCharge="soundCharge"
            :soundExchange="soundExchange"
            :soundCustomer="soundCustomer"
            :soundMember="soundMember"
            :overlay="overlay"
            v-on:move="move"
            ref="status"
        />
      </div>

    </v-app-bar>
    <alert-dialog
        :dialog.sync=dialog.alert
        :text=dialog.alertText
        v-on:alertAction="alertAction"
    ></alert-dialog>
  </div>

</template>

<script>


// import axios from 'axios'
import StatusBoardPc from "@/components/_Default/StatusBoard/Pc";
import StatusBoardMobile from "@/components/_Default/StatusBoard/Mobile";
import Notifications from "@/components/_Default/Notifications";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import {eventBus} from '@/plugins/eventBus'

export default {
  name: "AppBar",
  data: () => ({
    socketInterval: null,
    interval: false,
    drawer: false,
    boardLoadingFlag:true,
    overlay:true,
    boardList: Object,
    navigationHeight: 0,
    dashBoardItem: {},
    soundCharge:'',
    soundExchange:'',
    soundCustomer:'',
    soundMember:'',
    menuItems: [
      /*{icon:'mdi-home',title:'메인페이지',path:'/'},*/

      {
        icon: 'mdi-face-agent',
        title: '고객센터',
        path: '/Customer',
        items: [
          {title: '문의리스트', path: '/customer'},
          {title: '답변템플릿', path: '/autoAnswer'}
        ]
      },
      {
        icon: 'mdi-currency-krw',
        title: '충환전관리',
        path: '/Customer',
        items: [
          {title: '충전리스트', path: '/deposit/All'},
          {title: '환전리스트', path: '/withdrawal/All'},
        ]
      },
      /*{
        icon:'mdi-trophy',
        title:'스포츠',
        path:'/',
        items:[
            {title:'프리매치관리',path:'/prematch'},
            {title:'베팅내역(프리매치)',path:'/betHistory/SPORTS_GAME'},
            {title:'베팅현황(프리매치)',path:'/prematch/betState'},
            {title:'라이브관리',path:'/live'},
            {title:'베팅내역(라이브)',path:'/betHistory/LIVE'},
            {title:'종목관리',path:'/category'},
            {title:'팀명관리',path:'/teamName'},
            {title:'리그관리',path:'/league'},
            {title:'게임등록',path:'/prematch/regist'},
            {title:'게임등록(엑셀)',path:'/prematch/registByExcel'}
        ]
      },*/
      /*{
        icon: 'mdi-trophy',
        title: '미니게임',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory/MINI_GAME'},
          {title: '파워볼5분', path: '/powerball/'},
          {title: '파워볼3분', path: '/powerball3/'},
          /!*{title: '스피드키노', path: '/speedkeno'},*!/

        ]
      },
      {
        icon: 'mdi-trophy',
        title: '가상게임',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory/VIRTUAL_GAME'},
          {title: '가상축구', path: '/virtual/VSOCCER'},
          {title: '가상농구', path: '/virtual/VBASKETBALL'},
          {title: '개경주', path: '/virtual/VDOGRACING'},
          /!*{title: '베팅내역(축구)', path: '/betHistory/VSOCCER'},
          {title: '베팅내역(농구)', path: '/betHistory/VBASKETBALL'},
          {title: '베팅내역(개경주)', path: '/betHistory/VDOGRACING'},*!/
        ]
      },*/

      {
        icon: 'mdi-cards-spade',
        title: '카지노',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory/CASINO'},
        ]
      },
      {
        icon: 'mdi-clipboard', title: '공지/이벤트', path: '/board',
        items: [
          {title: '공지사항', path: '/notice/NOTICE'},
          {title: '이벤트', path: '/notice/EVENT'},
          {title: '팝업공지', path: '/notice/POPUP'},
          {title: 'FAQ', path: '/notice/FAQ'}
        ]
      },
      {
        icon: 'mdi-account', title: '회원', path: '/member',
        items: [
          {title: '회원목록', path: '/member'},
          /*{title: '회원등록', path: '/agency/regist'},*/
          {title: '회원별베팅', path: '/memberBetStatus'},
          {title: '쪽지관리', path: '/message/'},
          {title: '포인트지급', path: '/member/point'},
          {title: '현재접속자', path: '/logOn'},
/*          {title: '출석체크', path: '/attendance'},*/
        ]
      },
      {
        icon: 'mdi-calculator', title: '정산', path: '/calculation',
        items: [
          {title: '일별정산', path: '/calculation'},
          {title: '대리점별정산', path: '/calculation/agency'},
        ]
      },
      {
        icon: 'mdi-source-branch', title: '대리점', path: '/agency',
        items: [
          {title: '대리점목록', path: '/agency'},

        ]
      },
      {
        icon: 'mdi-cog-outline', title: '설정', path: '/setting',
        items: [
          {title: '사이트 설정', path: '/setting/site'},
          /*{title: '등급별 설정', path: '/setting/grade'},
          {title: '배당설정', path: '/setting/sports'},*/
          {title: '입금계좌', path: '/setting/deposit'},
          // {title: '차단IP관리', path: '/setting/blockip'},
          // {title: '은행관리', path: '/setting/bank'},
        ]
      },
      {
        icon: 'mdi-history', title: '로그', path: '/log/login',
        items: [
          {title: '로그인', path: '/log/login'},
          {title: '머니로그', path: '/log/money'},
          {title: '포인트로그', path: '/log/point'},
        ]
      },
    ],
    currPath: '',
    dialog: {
      alert: false,
      alertText: '',
      alertOption: {
        persistent: false
      },
      alertActionType: '',
    },
    stompClient: null,
    subscriptionId:'',
    connected: false,
  }),
  components: {
    StatusBoardPc,
    StatusBoardMobile,
    Notifications
  },

  methods: {
    alertAction: function () {
      this.dialog.alert = !this.dialog.alert
      if(this.dialog.alertActionType === 'logout') {
        this.$logout()
      }
    },
    setMenuTab: function () {
      this.currPath = this.$route.path
    },
    move: function (targetPath) {
      console.log(targetPath , this.currPath)
      if (targetPath === this.currPath) {
        eventBus.getData()
      } else {
        this.$router.push({path: targetPath})
      }

    },
    checkMenuTab:  function (targetPath) {
      console.log('checkMenuTab!!!')
      console.log(targetPath , this.currPath)
      if (targetPath === this.currPath) {
        eventBus.getData()
      } else {
        // this.$router.push({ path: targetPath })
      }
    },

    getData: async function () {

      if (this.boardLoadingFlag) {
        this.boardLoadingFlag = false
      await axios
          .get(`/admin/dashboards`,
              {
                params: {},
              })
          .then(({data}) => {
            this.dashBoardItem = data;
            this.overlay = false

            if(this.dashBoardItem.chargeInfo.requestedCount > 0 && this.soundCharge === ''){
              this.soundCharge = '<audio autoplay=\'true\' loop=\'true\' src=\'/charge.wav\' style=\'width:1px;height:1px\'/>'
            } else if (this.dashBoardItem.chargeInfo.requestedCount === 0) {
                this.soundCharge = ''
            }

            if(this.dashBoardItem.exchangeInfo.requestedCount > 0 && this.soundExchange === ''){
              this.soundExchange= '<audio autoplay=\'true\' loop=\'true\' src=\'/exchange.wav\' style=\'width:1px;height:1px\'/>'
            } else if (this.dashBoardItem.exchangeInfo.requestedCount === 0) {
                this.soundExchange = ''
            }

            if(this.dashBoardItem.customerCenterInfo.newCount > 0 && this.soundCustomer === ''){
              this.soundCustomer = '<audio autoplay=\'true\' loop=\'true\' src=\'/qna.wav\' style=\'width:1px;height:1px\'/>'
            } else if (this.dashBoardItem.customerCenterInfo.newCount === 0) {
                this.soundCustomer = ''
            }

            if(this.dashBoardItem.memberInfo.newCount > 0 && this.soundMember === ''){
              this.soundMember = '<audio autoplay=\'true\' loop=\'true\' src=\'/new_mem.wav\' style=\'width:1px;height:1px\'/>'
            } else if (this.dashBoardItem.memberInfo.newCount === 0) {
                this.soundMember = ''
            }
          })
          .catch(async ex => {
            console.log(ex)
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getData()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                  clearInterval(this.interval)
                  clearInterval(this.socketInterval)
                }
                this.dialog.alert = true;
                if (refreshTokenResult.msg !== '') {
                  this.dialog.alertText = refreshTokenResult.msg
                } else {
                  this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
                }

              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              if (ex.response.data.message !== '') {
                this.dialog.alertText = ex.response.data.message
              } else {
                this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
              }
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
              clearInterval(this.interval)
              clearInterval(this.socketInterval)
            } else {
              this.dialog.alert = true;
              if (ex.response.data.message !== '') {
                this.dialog.alertText = ex.response.data.message
              } else {
                this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
              }
            }
          })
        this.boardLoadingFlag = true
      }
    },

    upDateStatusBoard: function (data) {
      console.log('upDateStatusBoard',data)
      switch (Object.keys(data)[0]) {
        case 'chargeInfo':
          this.dashBoardItem.chargeInfo= data.chargeInfo
          if(this.dashBoardItem.chargeInfo.requestedCount > 0){
            this.soundCharge = '<audio autoplay=\'true\' loop=\'true\' src=\'/charge.wav\' style=\'width:1px;height:1px\'/>'
          }
          else {this.soundCharge =''}
          break;
        case 'exchangeInfo':
          this.dashBoardItem.exchangeInfo= data.exchangeInfo
          if(this.dashBoardItem.exchangeInfo.requestedCount > 0){
            this. soundExchange= '<audio autoplay=\'true\' loop=\'true\' src=\'/exchange.wav\' style=\'width:1px;height:1px\'/>'
          }
          else {this.soundExchange =''}
          break;
        case 'customerCenterInfo':
          this.dashBoardItem.customerCenterInfo= data.customerCenterInfo
          if(this.dashBoardItem.customerCenterInfo.newCount > 0) {
            this.soundCustomer = '<audio autoplay=\'true\' loop=\'true\' src=\'/qna.wav\' style=\'width:1px;height:1px\'/>'
          }
          else {this.soundCustomer =''}
          break;
        case 'memberInfo':
          this.dashBoardItem.memberInfo.newCount = data.memberInfo.newCount
          this.dashBoardItem.memberInfo.readyCount = data.memberInfo.readyCount
           if(this.dashBoardItem.memberInfo.newCount > 0){
             this.soundMember = '<audio autoplay=\'true\' loop=\'true\' src=\'/new_mem2.wav\' style=\'width:1px;height:1px\'/>'
           }
           else {this.soundMember =''}
          break;
        default :
          break;
      }

    },
    subScribeInplay: function () {
      this.stompClient.subscribe("/topic/dashboard", async tick => {
        this.subscriptionId = tick.headers.subscription
        await this.upDateStatusBoard(JSON.parse(tick.body));
      });
    },
    async connect() {
      this.stompClient = this.$initSocket()
      await this.stompClient.connect(
          {},
          async frame => {
            this.connected = true;
            this.subScribeInplay()

          },
          error => {
            this.connected = false;
          }
      );
    },
    disconnect() {
      this.stompClient.unsubscribe(this.subscriptionId)
      this.stompClient.disconnect()
      this.$socketDisconnect()
      this.connected = false;
      clearInterval(this.socketInterval)
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },

    setDrawer: function () {
      this.drawer = this.$vuetify.breakpoint.mdAndUp;
    },
    onResize: async function () {
      await this.$nextTick();
      this.navigationHeight = this.$refs.navigationDrawer.$el.clientHeight - (this.$refs.navigationDrawerTitle.$el.clientHeight + 40);
    },
    socketCheck: function () {
      if(!this.stompClient.connected) {
        this.connect();
      }
    }
  },
  mounted() {
    this.onResize()
    this.setDrawer()
    this.getData();
    this.setMenuTab()
  },

  created() {
    this.interval = setInterval(this.getData.bind(this), 10000);
    if(this.connected !== true){
      this.connect();
    }
    this.socketInterval = setInterval(this.socketCheck.bind(this), 3000);
  },

  destroyed() {
    clearInterval(this.interval)
  },
  beforeDestroy() {
    this.disconnect()
  },

  computed: {
    scrollerStyle() {
      return {
        height: `${this.navigationHeight}px`,
      }

    },
  },
  watch: {
    $route(to, from) {
      // console.log(to,from)
      this.getData()
      if(this.connected !== true){
        this.connect();
      }
      this.currPath = to.path
      // console.log('currpath!!@!',this.currPath)
    },

  }
}
</script>

<style>
.v-list-item--active .v-list-item__content .appBar {
  color: #c97515 !important;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 10px;
}

.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 48px;
}

</style>
