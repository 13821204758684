<template>
  <div
      v-if="search.use"
  >
    <v-dialog
        :value="dialog"
        @input="toggleDialog"
        max-width="290"
        content-class="dialogMobile"
    >
      <v-card class="pb-3">
        <v-card-title class="justify-space-between  pb-3">
          <div>
            검색
          </div>
          <div>
            <v-btn
                icon
                class=""
                x-large
                color="#4c4c4c"
                @click="toggleDialog"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <div v-if="!search.usedCalendar">
            <div class="d-flex align-center flex-column">
              <v-select
                  v-if="search.defaultSelectList.length > 0"
                  :items="search.defaultSelectList"
                  item-text="text"
                  item-value="value"
                  v-model="searchCategory"
                  solo
                  flat
                  full-width

                  color="success"
                  class=""
                  background-color="#4c4c4c"
                  style="width: 100%"
                  hide-details
              ></v-select>
              <v-text-field
                  v-if="search.defaultSelectList.length > 0"
                  v-model="searchInput"
                  solo
                  hide-details
                  flat
                  full-width
                  class=" my-1"
                  placeholder="검색어를 입력해주세요."
                  style="width: 100%"
                  background-color="#4c4c4c"
              ></v-text-field>
              <v-btn
                  tile
                  color="success"
                  class=""
                  block
                  x-large
                  @click="searchData"
              >
                검색
              </v-btn>
            </div>
          </div>
          <div
              v-else
          >
            <div class="d-flex align-center flex-column">
              <div class="my-1 w-100">
                <v-menu
                    v-model="startDateCalendars"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="startDate"
                        label="시작날짜"
                        prepend-icon="mdi-calendar"
                        class="ma-0 pa-0 pb-1"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="startDate"
                      @input="startDateCalendars = false"
                      
                  ></v-date-picker>
                </v-menu>
                <v-menu
                    v-model="endDateCalendars"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="endDate"
                        label="끝 날짜"
                        prepend-icon="mdi-calendar"
                        class="ma-0 pa-0"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="endDate"
                      @input="endDateCalendars = false"
                      
                  ></v-date-picker>
                </v-menu>
              </div>

              <v-select
                  v-if="search.defaultSelectList.length > 0"
                  :items="search.defaultSelectList"
                  item-text="text"
                  item-value="value"
                  v-model="searchCategory"
                  solo
                  flat
                  full-width

                  color="success"
                  class="w-100"
                  background-color="#4c4c4c"

                  hide-details
              ></v-select>
              <v-text-field
                  v-if="search.defaultSelectList.length > 0"
                  v-model="searchInput"
                  solo
                  hide-details
                  flat
                  full-width
                  class=" my-1"
                  placeholder="검색어를 입력해주세요."
                  style="width: 100%"
                  background-color="#4c4c4c"
              ></v-text-field>
              <v-btn
                  tile
                  color="success"
                  class=""
                  block
                  x-large
                  @click="searchData"
              >
                검색
              </v-btn>
            </div>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>

</template>

<script>
export default {
  name: "SearchFormMobile",
  data() {
    return {
      searchInput: '',
      searchCategory: this.search.defaultSelectValue,
      statusValue: this.search.memberStatusValue,
      startDateCalendars: false,
      endDateCalendars: false,
      startDate: this.search.startDate,
      endDate: '',
      agencyId: '',
      status: '',
      agencyList: [],
      eventList: [],

      //게임
      gameStatus: this.search.gameStatusValue,
      gameCode: this.search.gameCodeValue,
      gameEvent: this.search.eventList,
      showBetGame: false,
      //베팅내역
      betStatus: this.search.betStatusValue,
      sortBetAmount: false
    }
  },
  props: {
    search: {
      type:Object
    },
    dialog: {
      type: Boolean
    }
  },
  methods: {
    initSearchStatusUsingBetDialog: function () {
      this.startDate = this.search.startDate
      this.endDate = this.$endDate
      this.betStatus = this.search.betStatusValue
      this.menuCode = this.search.menuCodeListValue
      this.sortType = null
    },
    searchData: function () {
      let data = this.initSearchData()
      console.log('서치폼', data)
      this.toggleDialog();
      this.$emit('searchData', data);
    },
    downloadExcel: function () {
      let data = this.initSearchData()
      this.$emit('downloadExcel',data)
    },
    initSearchData: function () {
      let data = {}
      let startDateChangeFormat = this.startDate
      let endDateChangeFormat = this.endDate
      if (startDateChangeFormat !== undefined) {
        startDateChangeFormat = this.startDate.replace(/-/g, '')
      }
      if (endDateChangeFormat !== undefined) {
        endDateChangeFormat = this.endDate.replace(/-/g, '')
      }
      let searchInput = this.searchInput.trim()
      if (this.search.usedCalendar) {
        data = {
          startDate: startDateChangeFormat,
          endDate: endDateChangeFormat,
          category: this.searchCategory,
          data: searchInput
        }
      } else {
        data = {
          category: this.searchCategory,
          data: searchInput
        }
      }
      if (this.search.usedAgency) {
        data.agencyId = this.agencyId
      }
      if (this.search.usedMemberStatus) {
        data.memberStatus = this.statusValue
      }
      if (this.search.usedProcessStatus) {
        data.processStatus = this.processStatus
      }
      if (this.search.usedBetStatus) {
        data.betStatus = this.betStatus
      }
      if (this.search.usedSportsList) {
        data.sports = this.gameEvent
      }
      /*if (this.search.sortBetAmount) {
        data.sortBetAmount = this.sortBetAmount
      }
      if (this.search.sortHitAmount) {
        data.sortHitAmount = this.sortHitAmount
      }*/
      if (this.search.usedSortType) {
        data.sortType = this.sortType
      }
      if (this.search.usedAgencyDepth) {
        data.agencyDepth = this.agencyDepth
      }
      if (this.search.usePlace === 'game') {
        data.sports = this.gameEvent
        data.gameCode = this.gameCode
        data.status = this.gameStatus
        data.matchedAt = this.startDate
        data.showBetGame = this.showBetGame
      }
      if (this.search.virtualGame) {
        data.league = this.virtualLeague
      }
      if (this.search.usedMenuCode) {
        data.menuCode = this.menuCode
      }
      if (this.search.useAdminUpdated) {
        data.adminUpdated = this.adminUpdated
      }
      return data
    },
    getAgencyList: async function () {
      this.agencyList = await this.$getAgencyData().then(function (data) {
        return data.data
      })
    },
    getSportsList: async function () {
      this.eventList = await this.$getSportsData().then(function (data) {
        return data
      })
    },

    toggleDialog: function () {
      this.$emit('update:dialog', !this.dialog);
    },
  },
  mounted() {
    if (this.search.usedAgency) {
      this.getAgencyList();
    }
    if (this.search.usePlace === 'game' || this.search.usedSportsList) {
      this.getSportsList();
    }
  }
}
</script>

<style scoped>

</style>